.cfm-gr-menu{
  width: 165px;
  height: 165px;
  background-color: #a6bcea;
  border-radius: 4px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.cfm-gr-menu:hover{
  box-shadow: 0 5px 10px rgba(0,0,0,.15);
}

.cfm-gr-menu hr{
  margin: 8px;
}

.cfm-gr-menu-in{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.cfm-gr-menu a:link,a:visited,a:hover,a:active {
  text-decoration: none;
}

.cfm-gr-menu:hover .cfm-gr-menu-icon{
  color: white;
  text-align: center;
}

.cfm-gr-menu-icon{
  color: transparent;
  text-align: center;
}

.cfm-gr-menu-title{
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: xx-large;
  font-weight: 500;
  vertical-align: middle;
  text-align: center;
}

.cfm-gr-menu-subtitle{
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: medium;
  font-weight: 300;
  vertical-align: middle;
  text-align: center;
}

@import 'cfm-gr-menu';
@import 'cfm-gr-menu2';
@import 'cfm-title';
@import 'cloudcannon';

.fancybox-title {
    padding: 0 10 0 0;
}

.cfm-header{
    margin: 20px;
}

.align-img-left{
    float: left;
    padding-right: 15px;
    padding-bottom: 15px;
}

.cfm-centered-text{
    text-align: center;
}

//ul li a:link, a:visited {
//    color: red;
//}

.cfm-justify{
    text-align: justify;
    text-justify: inter-word;
}

//Aquestes tres classes serveixen per ajustar a la mateixa mida les imatges
.cfm-publicacions-thumbnail {
  position: relative;
  width: 90px;
  height: 130px;
  overflow: hidden;
  margin-left: 8px;
}
.cfm-publicacions-thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}
.cfm-publicacions-thumbnail img.portrait {
  width: 100%;
  height: auto;
}

.cfm-collaboradors-cbp-title {
    font-size: 8px !important;
    font-weight: 600 !important;
}

.cfm-2columnes {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
    -webkit-column-gap: 40px; /* Chrome, Safari, Opera */
    -moz-column-gap: 40px; /* Firefox */
    column-gap: 40px;
    // -webkit-column-rule-style: solid; /* Chrome, Safari, Opera */
    // -moz-column-rule-style: solid; /* Firefox */
    // column-rule-style: solid;
    // -webkit-column-rule-width: 1px; /* Chrome, Safari, Opera */
    // -moz-column-rule-width: 1px; /* Firefox */
    // column-rule-width: 1px;
}

.owl-item{
  width:auto !important;
}

.owl-recent-works-v1 .item img {
  max-height: 185px;
  height: auto;
  width: auto;
  display: block;
}

.owl-work-v1 .item a:hover img {
  opacity: 0.8;
  /* -webkit-transform: scale(1.2); */
  -moz-transform: scale(1.2);
  -o-transform: scale(1.0);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.cfm-gr-menu2{
  width: 165px;
  height: 165px;
  background-color: #white;
  border: 3px solid darkgrey;
  border-radius: 4px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.cfm-gr-menu2-in{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.cfm-gr-menu2 a:link,a:visited,a:hover,a:active {
  text-decoration: none;
}

.cfm-gr-menu2-icon{
  color: darkgrey;
  text-align: center;
}

.cfm-gr-menu2-title{
  color: white;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  margin-left: auto;
  margin-right: auto;
  background-color: darkgrey;
  font-family: 'Roboto', sans-serif;
  font-size: 350%;
  font-weight: 500;
  vertical-align: middle;
  text-align: center;
}

.cfm-gr-menu2-subtitle{
  margin-top: 20px;
  color: darkgrey;
  font-family: 'Roboto', sans-serif;
  font-size: medium;
  font-weight: 300;
  vertical-align: middle;
  text-align: center;
}
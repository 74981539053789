.cfm-title{
  text-align: left;
  vertical-align: middle;
}

.cfm-title-in-primary{
  //font-family: 'Roboto', sans-serif;
  font-size: 500%;
  //text-transform: uppercase;
  //background-color: rgb(244,67,54);
  color: white;
  //padding-left: 70px;
  //padding-right: 70px;
  padding-bottom: 3px;
  padding-top: 3px;
  margin-left: 0px;
  margin-right: 0px;
}

.cfm-title-in-secondary{
  //font-family: 'Roboto', sans-serif;
  font-size: 150%;
  //background-color: rgb(211,47,47);
  color: white;
  //padding-left: 70px;
  padding-bottom: 3px;
  padding-top: 3px;
}

.cfm-title-in-secondary-right{
  text-align: right;
  margin-bottom: -10px;
  //padding-right: 75px;
  font-size: 80%;
  margin-top: 3px;
}

.cfm-title-in-primary-right{
  //padding-right: 75px;
}

.cfm-title-in-secondary-right a{
  text-decoration: none;
  color: white;
}

.cfm-title-logo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: contain;
  //margin: 8px;
  //margin-right: 20px;
  margin-top: 17px;
  margin-left: 8px;
  float: right;
  border: 1px solid darkgrey;
  background-color: white;;
}

.cfm-title-udg-logo-in {
  width: 60px;
  margin-top: -50px;
  margin-left: 5px;
}

.cfm-title-cfm-logo-in {
  width: 60px;
  margin-top: -60px;
  margin-left: 5px;
}